@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
body {
  margin: 0;
  padding: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  font-family: 'Nunito', sans-serif;
  background: #000000;
  color: #f1f1f1;
}
#root{
  background: #000000;
  color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  color: #f1f1f1;
  text-decoration: none;
}
.App{
    display: flex;
}
.authErrorMessage{
  margin-top: 20px;
  color: #ff7b7b;
}
.menu{
  width: 13vw;
  background: #1e1e21;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: fixed;
  top: 10px;
  left: 10px;
  height: calc(100% - 20px);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0 10px;
}
.menu__list {
  display: flex;
  flex-direction: column;
}
/*.menu__list::before{*/
/*  content: "";*/
/*  background: red;*/
/*  position: relative;*/
/*  left: 13vw;*/
/*  box-shadow: 50px 20px 102px 46px #412086, 40px 109px 124px 55px #823495;*/
/*}*/
.menu__list-item{
  display: block;
  width: 100%;
  padding: 14px 0;
  padding-left: 10px;
  /*border-bottom: 1px solid #202124;*/
  box-sizing: border-box;
  /*background: #3B3170;*/
  margin-bottom: 10px;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: bold;
  transition: .1s;
  text-transform: uppercase;
  font-size: 12px;
}
.menu__list-item.active{
  background: #0077E4;
}
.menu__list-item:hover{
  background: #5389c9;
  transition: .1s;
}
.menu__list-item:last-child{
  border-bottom: none;
}
.chat__body{
  position: absolute;
  bottom: 0;
  right: 0;
  height: 54vh;
  width: 500px;

  background: #1f223c;
  border-top-left-radius: 15px;
  padding: 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  padding-bottom: 40px;
}
.chat__body-form-input{
  width: 100%;
}
.object-send-message.access{
  background: #357135;
  font-size: 16px;
  padding: 20px 20px;
}
.object-send-message.reject{
  background: #af6464;
  font-size: 16px;
  padding: 20px 20px;
}
.chat__body-form{
  position: absolute;
  z-index: 10;

  bottom: 0;
  right: 0;
  display: flex;
  width: 500px;
}
.chat__body-form > .chat__body-form-input{
  outline: none;
  padding: 5px 10px;
  background: #40456f;
  color: #f1f1f1;
}
.chat__body-form > button{
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

}
.chat__body-message{
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.chat__body-message > p{
  margin: 0;
  display: block;
  border-radius: 10px;
  padding: 5px 10px;
  box-sizing: border-box;
  background: #7373ff;
  max-width: 80%;
}
.chat__body-message.admin{
  justify-content: end;
}
.content{
  position: fixed;
  left: 15vw;
  height: calc(100% - 20px);
  width: 84vw;
  background: #1e1e21;
  top: 10px;
  bottom: 10px;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: scroll;
  background-color: #1e1e21;
}
thead{
  height: 60px;
  box-shadow: 0px 8px 14px -10px #b4b4b4;
}
thead > tr > th{
  text-align: left;
}
.table{
  width: 100%;
  border: 1px solid #2F2F2F;
  border-radius: 10px;
  /*background: rgba(0, 119, 228, 0.1);*/
}
.table tbody tr > td{
  max-width: 500px;
  overflow-x: hidden;
}
.table tbody tr:nth-child(odd){
  background-color: rgba(211, 211, 222, 0.1);
}
.table tbody tr:nth-child(even){
  background-color: rgba(211, 211, 222, 0.01);
}
input[type="date"]{
  border: none;
  outline: none;
  background-color: #7D8FA9;
  color: #1D232C;
  box-shadow: inset 0px 0px 15px -3px #3b3b3b;
  padding: 3px 24px;
  font-size: 15px;
  border-radius: 6px;
  margin-right: 10px;
}
button{
  border: none;
  outline: none;
  background-color: rgba(211, 211, 222, 0.12);
  color: #f1f1f1;
  /*box-shadow: inset 0px 0px 15px -3px #3b3b3b;*/
  padding: 4px 24px;
  font-size: 15px;
  /*border-radius: 6px;*/
  /*margin-right: 10px;*/
  cursor: pointer;
}
.chats-page{
  display: flex;
}
.chats-page_list-item{
  display: block;
  background-color: rgba(211, 211, 222, 0.12);
  border-bottom: 1px solid #606060;
  padding: 5px 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
}
.chats-page_list-item.active{
  background-color: #1e1e21;
}
.chats-page_list-item-header{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.chats-page_chat{
  max-height: 94vh;
}
.chats-page_chat-messages{
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 20px;
  box-sizing: border-box;
  padding-bottom: 70px;
  height: 100%;
}
.chats-page_chat-messages-item{
  width: 100%;
  margin-bottom: 6px;
}
.chats-page_chat-messages-item.admin{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.chats-page_chat-form{
  background-color: #313131;

  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  box-sizing: border-box;
  margin-top: -60px;
}
.chats-page_chat-form-input{
  display: block;
  width: 100%;
  outline: none;
  border: none;
  margin-right: 10px;
  font-size: 14px;
  padding-left: 10px;
  box-sizing: border-box;

  background: #868686;
  color: #f1f1f1;
}
.chats-page_info-row{
  color: #d5d5d5;
}
.chats-page_info-row-value{
  color: #fff;
  font-weight: bold;
}
.chats-page_info-row-value > a{
  border: 1px solid #f1f1f1;
  margin-left: 10px;
  font-size: 12px;
  padding: 4px 10px;
  background: #f1f1f1;
  color: #333;
}
.chats-page_chat-form-submit{
  background: none;
  border: none;
  display: block;
  width: unset;
  padding: 5px 10px;
}
.chats-page_chat-messages-item-text{
  background-color: rgba(211, 211, 222, 0.12);
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 5px;
  width: fit-content;

  display: flex;
  flex-direction: column;
}
.chats-page_chat-messages-item-text-time{
  font-size: 12px;
  color: #9f9f9f;
  display: block;
  text-align: right;
}
.chats-page_list{
  display: flex;
  flex-direction: column;
  width: 30%;

  max-height: 94vh;
  overflow-y: scroll;
}
.chats-page_info{
  width: 29%;
  margin-left: 1%;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(211, 211, 222, 0.12);
}
.chats-page_list::-webkit-scrollbar-thumb,
.chats-page_chat-messages::-webkit-scrollbar-thumb,
.chats-page_chat-messages::-webkit-scrollbar-track,
.chats-page_list::-webkit-scrollbar-track{
  border-radius: 0;
}
.chats-page_list::-webkit-scrollbar,
.chats-page_chat-messages::-webkit-scrollbar{
  width: 5px;
}
.chats-page_info-row{
  display: block;
}
.chats-page_info > fieldset:last-child{
  margin-top: 20px;
}
.chats-page_chat{
  width: 40%;

  background-color: rgba(211, 211, 222, 0.12);
  border-radius: 0 10px 10px 0;
  box-sizing: border-box;
}
.chats-page_list-item-address{
  font-size: 16px;
}
.chats-page_list-item-message{
  display: block;
  width: 94%;
  font-size: 14px;
  color: #888888;

  margin-top: 5px;
}
.chats-page_list-item-body{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.new_message_ping{
  display: block;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;

  margin-bottom: 10px;
  margin-right: 4px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.chats-page_list-item-created{
  font-size: 12px;
  color: #888888;
}

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 5px grey;*/
  border-radius: 10px;
  border: 1px solid rgba(211, 211, 222, 0.12);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(211, 211, 222, 0.12);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(211, 211, 222, 0.12);
}
.indexes-header-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.range_medians_rooms_price{
  display: block;
}
.background-ranges-median-rooms-price{
  background: #1C1F37;
  padding: 5px 10px;
  border-radius: 5px;
}
/* CONSTS */
.indexes-flex{
  display: flex;
  justify-content: space-between;
}
.indexes-flex > .indexes-flex-item:first-child{
  width: 59%;
}
.indexes-flex > .indexes-flex-item:last-child{
  width: 40%;
}
.indexes-flex > .indexes-flex-item:first-child .table > thead > tr:first-child > th:first-child{
  width: 100px;
}
.row-chart, .row-chart > .chartjs-size-monitor, .row-chart > canvas{
  height: 300px;
}
.object-table{
  width: 100%;
}
.object-table-row{
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
}
.row-flex > .object-table:first-child{
  border-right: 1px solid #fff;
}
.cell-images > img{
  display: none;
}
.cell-images > img:first-child{
  display: block;
  height: 50px;
  cursor: pointer;
}
.main-row{
  display: flex;
  flex-wrap: wrap;
}
.main-row-item{
  width: 18%;
  /*box-shadow: 0px 0px 12px -5px #f9f9f9;*/
  margin: 10px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 12px;
  background: rgba(211, 211, 222, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
}
.main-row-item:hover{
  background: rgba(211, 211, 222, 0.12);
}
.main-row-item-h1{
  font-size: 14px;
  display: block;
  color: #EEF0F4;
}
.main-row-item-h2{
  font-size: 22px;
  display: block;
  color: #DDE1E8;
}
.object-table-row-cell:first-child{
  width: 20%;
}
.object-table-row-cell:last-child{
  width: 70%;
}
.object-send-message{
  margin-top: 30px;
}
button[disabled]{
  color: #767676;
}
.row-flex{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.charges-table{
  width: 100%;
  border: 1px solid #fff;
}
.campagins-price-row{
  margin-top: 10px;
  background-color: #1C1F37;
  width: fit-content;
  padding: 20px;
}
h1{
  margin-top: 0;
}
.buttons-row{
  display: flex;
  margin-bottom: 10px;
}
.buttons-row > button{
  border-right: 1px solid #333;
  font-size: 20px;
}
.buttons-row > button:first-child{
  border-top-left-radius: 20px;
}
.buttons-row > button:last-child{
  border-bottom-right-radius: 20px;
}
.campagins-price-row > span{
  display: block;
}
.form-offer-price{
  margin-top: 20px;
}
.form-offer-price > input{
  font-size: 20px;
  outline: none;
  border: none;
  padding: 5px 10px;
}
.access-color{
  color: #00b200;
}
.form-offer-price > button{
  border-radius: 0;
  font-size: 20px;
  outline: none;
  border: none;
  padding: 0;
  padding: 5px 10px;
}
.charges-table .object-table-row-cell:last-child{
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 24px;
}
.charges-table .object-table-row-cell:first-child{
  width: 30%;
  display: flex;
  align-items: center;
}
.content-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.content-row > .left-side{
  width: 44%;
}
.content-row > .right-side{
  width: 54%;
}
.content-row > .right-side > .row{
  font-size: 24px;
  display: flex;
  align-items: center;
}
.usersTable{
  background: #353b4b;
  /*border: 1px solid #fff;*/
  box-sizing: border-box;
  padding: 10px;

  margin-top: 15px;
  font-size: 24px;
  width: 100%;
}
.offer-send-money-table-row{
  display: flex;
}
.usersTable td{
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 5px;
}
.charges-button-send{
  font-size: 24px;
  color: #fff;
  margin-top: 15px;
}
.auth__overlay{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.offer-send-money-table{
  width: 30%;
}
.send-money-offers-flex{
  display: flex;
}
.offer-send-money-table .object-table-row-cell{
  min-height: 35px;
}
.offer-send-money-table .object-table-row-cell:last-child{
  font-size: 18px;
}
.auth__form{
  display: flex;
  flex-direction: column;
  width: 300px;
  background: #323c48;
  padding: 40px 20px;
  border-radius: 10px;
}
.auth__form > input{
  margin-bottom: 20px;
  font-size: 16px;
  padding: 5px 10px;
  outline: none;
  border: none;
}
.auth__form > button{
  margin-top: 20px;
  font-size: 20px;
  width: 100%;
  border-radius: 0;
  padding: 10px;
  cursor: pointer;
}
.charges-page input[type="checkbox"]{
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.charges-table .object-table-row-cell:last-child > input{
  display: block;
  width: 100%;
  background: #353b4b;
  color: #fff;
  font-size: 24px;
  outline: none;
  border: none;
}
.savedText{
  color: #01b901;
  transition: .3s;
  display: block;
}
.kdsajjjj-dshjhg-sdlbmdkdploghjd-dddd{
  display: flex;
  flex-direction: column;
}
.mainPage-filter > button,
.mainPage-filter > input{
  margin: 0;
  display: block;
  border-radius: 0;
  box-shadow: none;
  background: rgba(211, 211, 222, 0.15);
  color: #fff;
  font-size: 18px;
}
.mainPage-filter > input:first-child{
  border-radius: 10px 0 0 10px;
}
.mainPage-filter > button{
  font-size: 16px;
  border-left: 1px solid #161B21;
  padding: 10px 15px;
}
.mainPage-filter > button:hover{
  background: rgba(211, 211, 222, 0.12);
}
.mainPage-filter > button:last-child{
  border-radius: 0 10px 10px 0;
}
.mainPage-filter{
  display: flex;
  margin-bottom: 10px;
  padding-left: 10px;
}


/* LOADER */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  left: 45%;
  top: 40%;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
.a-block{
  display: block;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.menu__list-item.mobile{
  display: none;
}
@media screen and (max-width: 1400px){
  body{
    font-size: 12px;
  }
}
.message-box-icon{
  display: none;
}
@media screen and (max-width: 600px){
  .menu{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    top: unset;
    border-radius: unset;
    height: 50px;
    padding-bottom: 20px;
  }
  .content{
    padding-bottom: 50px;
  }
  .objects-table > thead > tr > th:nth-child(2),
  .objects-table > tbody > tr > td:nth-child(2){
    display: none;
  }
  .objects-table > thead > tr > th:nth-child(4),
  .objects-table > tbody > tr > td:nth-child(4){
    display: none;
  }
  .object-table-row > .object-table-row-cell:nth-child(1){
    width: 30%;
  }
  .object-table-row > .object-table-row-cell:nth-child(2){
    width: 70%;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .object-table-row-cell > p{
    margin: 0;
  }
  .message-box-icon{
    display: block;
    width: 50px;
    height: 50px;
    position: fixed;
    right: 40px;
    bottom: 96px;
    background: #d3cff8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .message-box-icon > svg{
    height: 50px;
    width: 20px;
    fill: #141627;
  }
  .chat__body,
  .chat__body-form{
    display: none;
  }
  .buttons-accept-or-eject{
    display: flex;
  }
  .object-send-message{
    width: 100%;
    display: block;
    padding: 10px;
  }
  .object-send-message.access,
  .object-send-message.reject{
    margin: 0;
    font-size: 12px;
    padding: 10px;
    width: 100%;
    margin-bottom: 120px;
  }
  .row-flex{
    flex-direction: column;
  }
  .menu__list::before{
    display: none;
  }
  .menu__list{
    display: flex;
    flex-direction: row;
  }
  .menu__list-item.desktop{
    display: none;
  }
  .menu__list-item.mobile{
    display: block;
    padding: unset;
    margin-bottom: unset;
    border-radius: unset;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .menu__list-item.mobile > svg{
    width: 50px;
    height: 20px;
  }
  body{
    background-color: #000000;
  }
  .content{
    width: 100%;
    margin: 0;
    padding: 0;
    height: unset;
    left: unset;
    top: 0;
    right: 0;
    bottom: 0;

    padding-top: 20px;
  }
  .main-row{
    justify-content: space-around;
  }
  .main-row-item{
    width: 90%;
    margin: 0;
    margin-bottom: 20px;
  }
}